:local(.river) {
    @import '../sass_only/frontend/typography';
    @import '../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables';
    @import '../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins';
    @import '../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/grid';

    h1.river-title {
        @extend h1;
        text-transform: uppercase !important;
        color: $gray-87 !important;
        font-weight: $normal !important;
    }

    .row {
        [class^='col-'] {
            margin-bottom: 24px;
        }

        @media (min-width: $screen-md) {
            display: flex;
            margin-left: 0;
            margin-right: 0;

            [class^='col-'] {
                padding: 0;
                margin: 0 15px 24px 0;

                &:last-child {
                    margin-right: 0;
                }

                .blog-entry {
                    height: 100%;
                }
            }
        }

        @media (max-width: $screen-sm) {
          margin-left: 0;
          margin-right: 0;
        }
    }
}
