// Colors

// Neutrals
$gray-100: #000000; // true black; used seldomly, usually in shadows, tints
$gray-87: #222222; // primary text gray
$gray-80: #333333;
$gray-71: #4b4b4b;
$gray-56: #707070;
$gray-43: #9c9c9c;
$gray-30: #b3b3b3;
$gray-12: #e0e0e0;
$gray-5: #f3f3f3;
$gray-4: #f6f8fa;
$gray-3: #f8f8f8; // not really used, but part of the design system
$gray-0: #ffffff; // white

$white-smoke: #f6f6f6;
$very-light-gray: #ccc;

// Hues
$blue-digital: #126eb5;
$blue-digital-darken-20: mix($gray-100, $blue-digital, 20); // #0e5891
$blue-digital-darken-10: mix($gray-100, $blue-digital, 10); // #1063a3

$blue-antique: #8fa6bc;
$blue-antique-darken-20: mix($gray-100, $blue-antique, 20); // #728596
$blue-antique-darken-30: #657888;

$blue-brand: #00aaeb;

$blue-digital-icon: #1c6ca2;

$red: #ac031f;
$red-lighten-20: mix($gray-0, $red, 20); // #bd354c

$yellow: #e9ba15;
$yellow-lighten-20: mix($gray-0, $yellow, 20); // #edc844

$green: #65a205;
$green-lighten-20: mix($gray-0, $green, 20); // #84b537
$green-lighten-80: mix($gray-0, $green, 80); // #e0eccd
$green-20: #518204;
$green-30: #477104;

$magenta: #ce255d;
$magenta-darken-20: mix($gray-100, $magenta, 20);

$orange: #ee7101;
$orange-lighten-20: mix($gray-0, $orange, 20); // #f18d34
$orange-lighten-80: mix($gray-0, $orange, 80);
$orange-30: #a74f01;

$brownish-gray: #666666;

//3rd party brand colors
$facebook-blue: #3b5999;
$gmail-red: #db4437;
$yahoo-purple: #400191;
$twitter-blue: #55acee;

$tumblr-blue: #12609c;
$pinterest-red: #b8242a;
$instagram-blue: #4890c7;
$youtube-red: #cd201f;

// Functional Color Variables
$divider-color: $gray-12;

$night-blue: #040c41;
$very-light-pink: #cfcfcf;
$brownish-grey-two: #676767;
$steel-three: #272727;

//New PBS palette colors
$pbs-blue: #2638c4;
$medium-blue: #0f1e8c;
$navy-blue: #0a145a;
$red: #d63a38;
$passport-blue: #2b92ff;
$darkest-blue: #02072c;
$dark-gray: #60738a;
$light-gray: #eef0f5;
$lightest-gray: #f6f8fa;
$darker-blue: #000525;
$yellow: #ffcf00;
$yellow-warning: #ecc844;
$yellow-warning-dark: #8a6d3b;
$teal: #48d3cd;
$white: #ffffff;
$midnight-blue: #040c41;
$light-blue: #486cd8;
$dark-red: #7f170e;
$dark-rat: #222222;
$blue-rat: rgba(18, 110, 181, 0.1);
$blue-rat-light: #94a1b2;
$azureish-white: #d4e5fb;

$component-color: #ffb752;
$component-color-disabled: #ffd092;
$component-color-20: #ff9b0f;

// Resize image
$blue-dialog-title: #146eb5;
$blue-icon: #2c7dbc;
$gray-input-border: #d5d5d5;

// React Select
$gray-border-menu: #e6e6e6;
$gray-option-selected: #f5faff;
$gray-group-heading: #818181;

// Pill buttons styling
$basic-darker-blue: #2638C4;
$basic-hover-blue: #0F1F8C;
