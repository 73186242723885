@import 'styles/custom/includes';
@import './filters';

$entry-padding: 14px;

:local(.blogEntry) {
    &.with-image {
        .category-wrapper {
            margin-top: -18px;
            text-align: center;
        }
    }

    img {
        max-width: 100%;
        max-height: 100%;
    }

    .title {
        &,
        * {
            color: $brownish-gray;
            font-size: $nancy;
            font-weight: $normal;
            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }
        margin-top: 0;
        margin-bottom: 20px;
    }

    .meta {
        margin-bottom: 20px;
        color: $gray-87;
        font-size: $cloe;

        .byline {
            margin-right: 4px;
        }

        .author {
            font-weight: $bold;
            text-transform: capitalize;
        }
    }

    .entry-image-wrapper {
        text-align: center;
        background-color: transparent;
        border: 1px solid $gray-12;
        box-sizing: content-box;
        position: relative;
        padding-top: calc(7 / 18 * 100%); // 18:7 in %
        width: calc(100% - 2px);

        .entry-image {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }

    .category-wrapper {
        margin-bottom: $gap-large;
        position: relative;

        .category {
            display: inline-block;
            border: solid 1px #979797;
            padding: 4px 14px;
            color: $brownish-gray;
            font-size: $oscar;
            text-transform: uppercase;
            background-color: $gray-0;
            max-width: 80%;
        }
    }

    .content-body {
        color: $brownish-gray;
        font-size: $oscar;
        margin-bottom: 20px;
    }

    ul.tags {
        @extend .filters;
        margin-bottom: 0;
        text-align: left;

        i {
            margin-right: $gap-quarter;
        }
    }

    .social {
        display: inline-block;
        width: 35px;
        height: 35px;
        border: solid 1px #dddddd;
        padding: $gap-half;
        text-align: center;
        vertical-align: middle;
        margin-bottom: 20px;
        margin-right: 10px;
        line-height: 1;
        cursor: pointer;

        &:last-child {
            margin-right: 0;
        }

        &:after {
            content: '';
            display: inline-block;
            vertical-align: middle;
            height: 100%;
        }

        img {
            vertical-align: middle;
        }
    }

    .cta {
        font-size: $oscar;
        font-weight: $bold;
        margin-bottom: 20px;
        display: inline-block;
        text-transform: uppercase;

        &:hover,
        &:focus {
            text-decoration: underline;
        }

        .fa {
            font-size: $nancy;
            padding-left: 5px;
            display: inline-block;
            vertical-align: middle;
            padding-bottom: 3px;
        }
    }

    footer {
        text-align: center;
    }
}
