@mixin clearfix() {
    &::before,
    &::after {
        content: '';
        /* 1 */
        display: table;
        /* 2 */
    }
    &::after {
        clear: both;
    }
}

@mixin textColor($class:'') {
    $text-color: $gray-0;
    font-weight: $normal;
    @if $class !='filled' {
        $text-color: $gray-71;
    }
    color: $text-color;
    &:hover,
    &:focus {
        color: $text-color;
        text-decoration: none;
    }
}

@mixin default-pill-buttons() {
    border: 2px solid  $basic-darker-blue;
    border-radius: $pill-button-border-radius;
    color: $basic-darker-blue;
    font-weight: 600;
    font-size: 17px;
    position: relative;
    height: auto;
    min-height: 35px !important;
    padding: 4px 16px;

    &.text-container, &.button-item-text {
        padding: 4px 8px !important;

        .ck.ck-reset {
            min-height: 30px;
        }
    }

    p, .ck-placeholder {
        font-weight: 600;
        height: fit-content;
        vertical-align: middle;

        &:before {
            margin-left: 3px;
        }
    }
}

@mixin pill-buttons($class: '') {
    @if $class == 'pill-button-container' {
        @include default-pill-buttons();

        &.video-cta {
            padding: 4px 32px 4px 16px;
        }

        p, .ck-placeholder {
            color: $basic-darker-blue;
        }

        &:hover {
            border: 2px solid $basic-hover-blue;
            background-color: $basic-hover-blue;
            color: $white;
            cursor: pointer;
            border-radius: $pill-button-border-radius;

            p, .ck-placeholder {
                color: $white;
            }
        }
    }

}

@mixin pill-buttons-filled($class: '', $theme: '') {
    @if $class == 'pill-button-container-filled' {
        @include default-pill-buttons();

        @if $theme == 'light-theme' {
            color: $white;

            p, .ck-placeholder {
                color: $white;
            }
        }

        @if $theme == 'dark-theme' {
            color: $pbs-blue;
            border-color: $pbs-blue;

            p, .ck-placeholder {
                color: $pbs-blue;
            }
        }

        &:hover {
            cursor: pointer;
            border-radius: $pill-button-border-radius;

            @if $theme == 'light-theme' {
                border: 2px solid $basic-hover-blue;
                background-color: $basic-hover-blue;
                color: $white;

                p, .ck-placeholder {
                    color: $white;
                }
            }

            @if $theme == 'dark-theme' {
                border: 2px solid $pbs-blue;
                background-color: $white;
                color: $basic-hover-blue;

                p, .ck-placeholder {
                    color: $basic-hover-blue;
                }
            }
        }
    }
}


@mixin pill-buttons-link($class: '') {
    @if $class == 'pill-button-container' {
        border: 2px solid  $basic-darker-blue;
        border-radius: $pill-button-border-radius;
        color: $basic-darker-blue;
        font-weight: 600;
        font-size: 17px;
        position: relative;
        height: auto;
        min-height: 35px !important;
        padding: 4px 32px 4px 16px;

        &:hover {
            border: 2px solid $basic-hover-blue;
            border-radius: $pill-button-border-radius;
            background-color: $basic-hover-blue;
            color: $white;
            cursor: pointer;
        }
    }
}

@mixin component-placeholder($height:100px, $imgHeight:90px) {
    background-color: $gray-12;
    text-align: center;
    vertical-align: middle;
    width: 100%;
    height: #{$height};
    line-height: #{$height};

    .placeholder-text {
        font-size: 20px;
        font-weight: $semibold;
        color: $gray-71;
        display: inline-block;
        vertical-align: middle;
    }

    img {
        max-width: #{$imgHeight};
        max-height: #{$imgHeight};
        margin: 0 5px;
    }
}

@mixin responsive-wrapper($aspectRationWidth, $aspectRatioHeight, $target) {
        width: 100%;
        padding-bottom: $aspectRatioHeight / $aspectRationWidth * 100%;
        position: relative;

        #{$target} {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
}

@mixin insert-icon($icon: "\f105") {
    content: $icon;
    font-family: "Font Awesome 5 Free";
    font-weight: 600;
    font-style: normal;
    text-decoration: inherit;
    -webkit-font-smoothing: antialiased;
}

@mixin cta-btn($displayIcon: false) {
    margin-top: $gap-large;
    font-size: $ernie;
    line-height: 1.4;
    padding: $gap;
    // the color is set in profile-grid.css
    // which will be the default border-color
    // (which is currentColor)
    // adding currentColor so that is more explicit
    border: 2px solid currentColor;
    display: inline-block;
    position: relative;
    text-decoration: none;
    word-wrap: break-word;
    max-width: 100%;

    & {
        transition:
            background-color $duration,
            border-color $duration,
            color $duration
        ;
    }

    a {
        text-decoration: none;
    }

    @if $displayIcon {
        padding-right: 35px;

        &:after {
            @include insert-icon("\f105");
            display: flex;
            align-items: center;
            position: absolute;
            height: 100%;
            right: 15px;
            top: 0px;
            font-size: 22px;
        }
    }
    .ck_content * {
        font-size: $ernie;
        line-height: 1.4;
        text-decoration: none;
        word-wrap: break-word;
        max-width: 100%;
    }
    .ck.ck-editor__editable_inline>:first-child {
        margin-top: 0;
    }
    .ck.ck-editor__editable_inline>:last-child {
        margin-bottom: 0;
    }

}

@mixin pbsFonts($path) {
    @font-face {
        font-family: 'PBSSans';
        font-style: normal;
        font-weight: $normal;
        font-display: swap;
        src: url('#{$path}/PBSSans.eot'); /* IE9 Compat Modes */
        src: local('PBS Sans'), local('PBSSans'),
           url('#{$path}/PBSSans.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
           url('#{$path}/PBSSans.woff2') format('woff2'), /* Super Modern Browsers */
           url('#{$path}/PBSSans.woff') format('woff'), /* Modern Browsers */
           url('#{$path}/PBSSans.ttf') format('truetype'), /* Safari, Android, iOS */
           url('#{$path}/PBSSans.svg#PBSSans') format('svg'); /* Legacy iOS */
    }

    @font-face {
        font-family: 'PBSSans';
        font-style: normal;
        font-weight: $bold;
        font-display: swap;
        src: url('#{$path}/PBSSans-Bold.eot'); /* IE9 Compat Modes */
        src: local('PBS Sans Bold'), local('PBSSansBold'),
           url('#{$path}/PBSSans-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
           url('#{$path}/PBSSans-Bold.woff2') format('woff2'), /* Super Modern Browsers */
           url('#{$path}/PBSSans-Bold.woff') format('woff'), /* Modern Browsers */
           url('#{$path}/PBSSans-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
           url('#{$path}/PBSSans-Bold.svg#PBSSans-Bold') format('svg'); /* Legacy iOS */
    }

    @font-face {
        font-family: 'PBSSans';
        font-style: italic;
        font-weight: $normal;
        font-display: swap;
        src: url('#{$path}/PBSSans-It.eot'); /* IE9 Compat Modes */
        src: local('PBS Sans It'), local('PBSSansIt'),
           url('#{$path}/PBSSans-It.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
           url('#{$path}/PBSSans-It.woff2') format('woff2'), /* Super Modern Browsers */
           url('#{$path}/PBSSans-It.woff') format('woff'), /* Modern Browsers */
           url('#{$path}/PBSSans-It.ttf') format('truetype'), /* Safari, Android, iOS */
           url('#{$path}/PBSSans-It.svg#PBSSans-It') format('svg'); /* Legacy iOS */
    }

    @font-face {
        font-family: 'PBSSans';
        font-style: normal;
        font-weight: $light;
        font-display: swap;
        src: url('#{$path}/PBSSans-Light.eot'); /* IE9 Compat Modes */
        src: local('PBS Sans Light'), local('PBSSansLight'),
           url('#{$path}/PBSSans-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
           url('#{$path}/PBSSans-Light.woff2') format('woff2'), /* Super Modern Browsers */
           url('#{$path}/PBSSans-Light.woff') format('woff'), /* Modern Browsers */
           url('#{$path}/PBSSans-Light.ttf') format('truetype'), /* Safari, Android, iOS */
           url('#{$path}/PBSSans-Light.svg#PBSSans-Light') format('svg'); /* Legacy iOS */
    }
}
