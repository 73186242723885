@import 'styles/custom/includes';
@import '_placeholder';

:local(.videoList) {
    @import 'sass_only/frontend/typography';
    @include placeholder();

    .videos {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -16px;
        @include breakpoint($below-sm) {
          margin: 0;
        }
    }

    .video-item {
        width: 100%;
    }

    &.column-12 {
        .video-item {
            width: (100% / 4);
            @include breakpoint($below-md) {
                width: (100% / 2);
            }
            @include breakpoint($below-sm) {
                width: 100%;
            }
        }
    }

    &.column-8 {
        .video-item {
            width: (100% / 3);
            @include breakpoint($below-lg) {
                width: (100% / 2);
            }
            @include breakpoint($below-xs) {
                width: 100%;
            }
        }
    }

    &.column-6 {
        .video-item {
            width: (100% / 2);
            @include breakpoint($below-md) {
                width: 100%;
            }
        }
    }

    .video-list-title {
        .ck-content * {
            @extend h1;
            margin-bottom: 16px !important;
        }
    }

    .show-title {
        font-size: $elmo;
        font-weight: $semibold;
        text-align: left;
        color: $gray-71;
        text-transform: uppercase;
        margin: 10px 0 0 0;
        &:empty {
            max-width: 50%;
        }
    }

    .video-title {
        font-size: $oscar;
        color: $gray-87;
        margin: 10px 0 20px 0;
    }

    .video-meta {
        font-size: $elmo;
        color: $gray-87;
        margin: 20px 0 15px 0;
        text-transform: capitalize;
        &:empty {
            max-width: 70%;
        }
    }

    .description {
        color: $gray-87;
        font-size: $ernie;
        margin: 20px 0 0 0;
    }

    .load-more-wrapper {
        text-align: center;

        .load-more {
            text-align: center;
            @include cta-btn(false);

            &.disabled {
                color: $gray-12 !important;
                border: 2px solid $gray-12 !important;
                background: transparent !important;
                cursor: default;
                pointer-events: none;
            }

            &.pill-button-component {
                @include pill-buttons('pill-button-container');
            }
        }
    }
}
