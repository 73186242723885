@import 'styles/custom/includes';

$control-btn-spacing: 5px;

:local(.pager){
    list-style: none;
    cursor: pointer;
    text-align: center;
    padding: $gap 0;
    border-top: $divider;
    font-size: $ernie;
    color: $gray-80;

    li {
        display: inline-block;
        text-align: center;

        &, a {
            color: inherit;
        }

        &.active {
            a {
                color: $gray-80;
                text-decoration: underline;
            }
        }

        &.previous {
            margin-right: $control-btn-spacing;
            a:focus {
                outline: none;
            }
        }

        &.next {
            margin-left: $control-btn-spacing;
            a:focus {
                outline: none;
            }
        }

        &:not(.previous):not(.next),
        .fa-angle-double-left,
        .fa-angle-double-right,
        .fa-angle-left,
        .fa-angle-right {
            display: inline-block;
            min-width: 15px;
            margin: 0 5px;

        }
    }
}
