@import 'styles/custom/includes';
@import './filters';

:local(.blogRiver) {
    @import 'sass_only/frontend/typography';

    .row {
        margin-left: 0;
        margin-right: 0;
        [class^='col-'] {
            margin-bottom: $gap-large;
        }

        @media (min-width: 992px) {
            display: flex;

            [class^='col-'] {
                padding: 0;
                margin: 0 15px $gap-large 0;

                &:last-child {
                    margin-right: 0;
                }

                .blog-entry {
                    height: 100%;
                }
            }
        }
    }

    h1.river-title,
    .river-title .ck-content * {
        @extend h1;
        text-transform: uppercase;
        color: $gray-87;
        font-weight: $normal;
    }

    .river-title {
        padding: 15px 0;

        .ck-content h1,
        .ck-content * {
            padding: 0;
            margin: 0;
        }
    }

    .categories,
    .tags {
        @extend .filters;
        margin-bottom: 34px;
    }

    .load-more-wrapper {
        text-align: center;

        .load-more {
            text-align: center;
            @include cta-btn(false);

            &.pill-button-component {
                @include pill-buttons('pill-button-container');
            }
        }
    }

    .pager-widget {
        border-top: none;
    }
}
