@import 'styles/custom/includes';

:local(.videoCTA) {
    @include cta-btn(true);
    &:hover {
        text-decoration: none;
    }
    &:empty:after {
        content: '';
    }

    .video-cta-text {
        .ck-content * {
            padding: 0;
            min-height: 0;
        }
    }

    &.pill-button-component {
        @include pill-buttons('pill-button-container');
    }

}
