@mixin placeholder() {
    .loading-bg {
        @keyframes pulse {
            0% {
                opacity: .6
            }

            50% {
                opacity: 1
            }

            to {
                opacity: .6
            }
        }

        background-color: $gray-12;
        animation: pulse 1.5s infinite;
    }

    .shape-placeholder,
    .shape-placeholder-fill,
    .text-placeholder {
        &:empty, &-long:empty {
            border-color: transparent !important;
            &:before, &:after {
                @extend .loading-bg;
                display: block;
                min-width: 100px;
            }

            &:before {
                content: '\00a0';
            }
        }

        &-long {
            &:empty {
                &:before {
                    min-height: 3 * 1.5em;
                    content: '\00a0';
                    background: repeating-linear-gradient(0deg, $gray-12, $gray-12 1em, $gray-0 1em, $gray-0 1.5em);
                }
            }

            &.double {
                &:before {
                    min-height: 5.9 * 1.5em;
                }
            }
        }
    }

    .shape-placeholder-fill {
        &:empty{
            background-color: transparent;
        }
    }

    .shape-placeholder {
        border-radius: 0;
        &:empty {
            @extend .loading-bg;
        }
        &-fill{
            &:empty {
                &:before, &:after {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    border-radius: 0;
                }
            }
        }
    }

}