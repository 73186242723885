// gutter widths;
// these should be used for margin, padding, top, left, right, bottom
$gap: 16px;
// smaller
$gap-three-quarts: ($gap / 1.33); // 12px
$gap-half: ($gap / 2); // 8px
$gap-quarter: ($gap / 4); // 4px
// larger
$gap-large: ($gap * 1.5); // 24px
$gap-double: ($gap * 2); // 32px
$gap-larger: ($gap * 2.5); // 40px
$gap-triple: ($gap * 3); // 48px
$gap-quad: ($gap * 4); // 64px

$vertical-spacing: 2.127vw; //should equal bootstrap's gutter width

// breakpoint variables
$xs: 480px;
$sm: 768px;
$md: 1024px;
$lg: 1280px;


$max-width: $lg;

//drag and drop
$max-drag-preview-height: 600px;

// "special" breakpoints: contain styles within lower/upper bounds
$below-xs: (max-width: $xs - 1);
$below-sm: (max-width: $sm - 1);
$above-sm: (min-width: $sm);
$sm-only: (min-width: $sm, max-width: $md - 1);
$below-md: (max-width: $md - 1);
$above-md: (min-width: $md);
$below-md: (max-width: $md - 1);
$md-only: (min-width: $md, max-width: $lg - 1);
$below-lg: (max-width: $lg - 1);
$above-lg: (min-width: $lg);
$lg-to-sm: (min-width: $sm, max-width: $lg - 1);

$mobile-menu-cap: 550px;
$mobile-menu-ratio: 80%;
$mobile-menu-width: $mobile-menu-cap * .8;

$sidebar-width: 299px;

// box shadow used on landing page components;
$shadow: 0 1px 3px rgba($gray-100, .45);

// for inset shadows, mainly used in menus
$inset-shadow-right: inset -10px 0 10px -10px rgba($gray-100, .25);
$inset-shadow-left: inset 10px 0 10px -10px rgba($gray-100, .25);

// Font/text base size
$font-size-base: 10px;
$font-size-medium: 14px;

// line heights
$line-height: 1.625;

// variables used in font weight declarations
$light: 300;
$normal: 400;
$semibold: 600;
$bold: 700;
$black: 900;

// Font size variables - selected based on frequency of use
$dustin: 1rem;
$michelle: 1.1rem;
$abby: 1.2rem;
$cloe: 1.3rem;
$elmo: 1.4rem;
$zoe: 1.5rem;
$ernie: 1.6rem;
$oscar: 1.8rem;
$snuffy: 2rem;
$frankie: 2.2rem;
$nancy: 2.4rem;
$benji: 2.5rem;
$charlie: 3rem;
$socorro: 3.2rem;
$john: 3.5rem;
$ida: 3.6rem;

// Font Stacks
$sans-serif: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
$serif: Arvo, Garamond, "Times New Roman", serif;
$monospace: Menlo, Monaco, Consolas, "Courier New", monospace;

// for border radius usage:
$radius: 2px;

// for animations and transitions
$duration: .3s;

// for thin dividers
$divider: 1px solid $gray-12;

// old declarations of breakpoints - will merge with the others soon
$screen: 'all';

// focus state
$focusstate: 3px solid $blue-brand;

// pbs rebranding fonts
$fonts-url: 'https://www-tc.pbs.org/pbsfonts/v1.2';

// pbs rebranding buttons
$pill-button-border-radius: 28.6px;
