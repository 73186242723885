@import 'styles/custom/includes';

:local(.videoItem) {
    padding: $gap;
    &.passport-video .thumbnail-wrapper:after {
        content: '';
        position: absolute;
        top: 13px;
        left: 13px;
        background-image: url('./media/passport.svg');
        width: 37px;
        height: 37px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        z-index: 1;
    }
    @media (max-width: 767px) {
      padding: $gap 0;
    }

    .thumbnail-wrapper {
        position: relative;
        display: block;
        width: 100%;
        background-color: $gray-12;
        @include responsive-wrapper(16, 9, '.thumbnail-img');

        &:hover{
            .play-icon {
                display: block;
            }
        }

        .thumbnail-img {
            display: inline-block;
            max-width: 100%;
            max-height: 100%;
            z-index: 1;
            opacity: 0;
            transition: opacity 0.5s ease-in;
            margin: 0 auto;
            top: 50%;
            transform: translateY(-50%);
            &.loaded { opacity: 1; }
        }

        .play-icon {
            display: none;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 64px;
            height: 64px;
            margin-top: -32px;
            margin-left: -32px;
            pointer-events: none;
            z-index: 1;
        }
    }
}
