@import 'styles/custom/includes';

:local(.videoPlayer) {
    display: flex;
    padding: $gap 0;

    @include breakpoint($below-md) {
        display: block;
    }

    &.overlay,
    &.overlay-console {
        &:not(.overlay-console) {
            position: fixed;
            background-color: $white;
        }

        &:not(.overlay) {
            position: absolute;
        }

        $top-spacing: $gap-double;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10002;
        padding: $top-spacing 0 0 0;

        .right-side {
            padding: 0 $gap;
            .show-title {
                color: $gray-71 !important;
            }
        }

        .close {
            $size: 18px;
            width: $size;
            height: $size;
            cursor: pointer;
            top: ($top-spacing - $size) / 2;
            right: ($top-spacing - $size) / 2;
            position: absolute;
            opacity: 1;
            text-shadow: none;
            opacity: 0.8;

            &:hover {
                opacity: 0.5;
            }
        }
    }

    .rsponsive-player-wrapper {
        @include responsive-wrapper(16, 9, 'iframe');
        background-color: $gray-100;
    }

    .left-side {
        max-width: 720px;
        flex-basis: 720px;
        flex-shrink: 1;

        @include breakpoint($below-md) {
            max-width: 100%;
        }
    }

    .right-side {
        min-width: 300px;
        flex-basis: 33%;
        flex-shrink: 0;
        flex-grow: 1;
        margin-left: 2%;

        .passport-icon {
            width: 20px;
            height: 20px;
            margin-right: 5px;
        }

        @include breakpoint($below-md) {
            margin: 0;
        }
    }
}
