@import 'styles/custom/includes';
@import 'styles/custom/mixins';

body {
    font-family: 'PBSSans', $sans-serif;
    color: $gray-87,
}

h1,
h2,
h3,
h4,
h5 {

    a {
        &,
        &:hover,
        &:focus {
            color: $gray-87;
        }
    }
}

h1 {
    margin: 0 0 $gap 0;
    font-size: $benji;
    font-weight: $light;
    line-height: 1.45;
}

h2 {
    margin: 0 0 $gap-quarter 0;
    font-size: $snuffy;
    font-weight: $light;
    line-height: 1.45;
}

h3 {
    margin: 0 0 $gap-quarter 0;
    font-size: $ernie;
    font-weight: $normal;
    line-height: 1.45;
}

h4 {
    margin: 0 0 $gap-quarter 0;
    font-size: $zoe;
    font-weight: $normal;
    line-height: 1.45;
}

h5 {
    margin: 0;
    font-size: $abby;
    font-weight: $bold;
    text-transform: uppercase;
    line-height: 1.45;
}

h6 {
    margin: 0;
    font-size: $cloe;
    font-weight: $normal;
}

p {
    margin: 0 0 $gap 0;
    font-size: $ernie;
    font-weight: $normal;
    line-height: $line-height;
}

.slim {
    font-weight: $light;
}

cite {
    font-style: italic;
    color: #869cad;
}

pre, code {
    font-family: $monospace;
}

pre {
    color: #666;
    word-wrap: break-word;
    white-space: normal;
}

code {
    color: #d14;
    background-color: #f7f7f9;
    border: 1px solid #e1e1e8;
}

a {
    &:hover,
    &:focus {
      text-decoration: underline;
    }
}
