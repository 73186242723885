@import 'styles/custom/includes';

.filters {
    display: block;
    border: 0;
    width: auto;
    padding: 0;
    margin: 0 0 10px 0;
    max-width: 100%;
    background-color: transparent;

    li.filter {
        background-color: transparent;
        margin: 0 6px 6px 0;
        text-shadow: none;
        font-size: $cloe;
        font-weight: $bold;
        text-transform: capitalize;
        white-space: normal;
        display: inline-block;
        padding: 0;
        text-transform: uppercase;
        margin-right: 15px;
        & > * {
            padding: $gap-half $gap;
            display: inline-block;
            cursor: pointer;
            text-decoration: none;
        }
    }
}
